import React from 'react';
import {AppState} from '../../App';

import './Analyses.scss';

interface AnalysesProps {
  state: AppState;
}

export default function Analyses({state}: AnalysesProps) {
  return (
    <div className="Analyses">
      <h1>Analyses</h1>
      <div className="analyses-container">
        {state.analyses.map((analysis) => {
          return (
            <div key={analysis.TITLE}>
              <div className="thumbnail">
                <a href={analysis.LINK}>
                  <img src={analysis.PICTURE} alt={analysis.TITLE} />
                </a>
              </div>
              <div>
                <a className="title" href={analysis.LINK}>
                  {analysis.TITLE}
                </a>
                <p>{analysis.AUTHOR}</p>
                <p className="date">{analysis.DATE.format('DD MMMM YYYY')}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
