import React from 'react';
import {AppState} from '../../App';

import './About.scss';

interface AboutProps {
  state: AppState;
}

export default function About({state}: AboutProps) {
  return (
    <div className="About">
      <h1>About</h1>
      <h2>Meet the team</h2>
      <div className="team">
        {state.team.map((member) => {
          return (
            <div key={member.EMAIL}>
              <div className="thumbnail">
                <img src={member.PICTURE} alt={member.NAME} />
              </div>
              <div>
                <p className="name">{member.NAME}</p>
                <p>{member.TITLE}</p>
                <p className="email">{member.EMAIL}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
