import React, {useState} from 'react';
import {AppState, DataRow} from '../../App';
import {Table, Tag, Select, DatePicker, Button, Tooltip} from 'antd';
// import {countries} from 'country-flag-icons';
import 'country-flag-icons/3x2/flags.css';

import './Tracker.scss';
import Search from 'antd/lib/input/Search';
import {
  BackwardOutlined,
  CopyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {useParams} from 'react-router-dom';

interface TrackerProps {
  state: AppState;
}

export const TOPICS_COLOR_MAP = new Map([
  ['VIS', 'purple'],
  ['GOV', 'blue'],
  ['PAR', 'geekblue'],
  ['ECO', 'orange'],
  ['MIL', 'green'],
  ['CIV', 'magenta'],
  ['CHI', 'red'],
  ['CYB', 'gold'],
  ['CUL', 'pink'],
  ['AID', 'yellow'],
  ['HRA', ''],
]);

function generateCitation(dataRow: DataRow): string {
  // Format the date in the desired format
  const formattedDate = dataRow.date.format('DD/MM/YYYY');

  // Trim the event text to 7 words
  const eventExcerpt = dataRow.event.split(' ').slice(0, 10).join(' ');

  // Assuming 'Kironska, K., et al' is a static part of every citation
  // eslint-disable-next-line max-len
  let citation = `Kironska, K., et al, "${eventExcerpt}...", EU-TW Tracker by CEIAS, ${formattedDate}`;

  // Append link to data entry
  citation += `, ${window.location.href}/${dataRow.id}`;

  return citation;
}

export default function Tracker({state}: TrackerProps) {
  const [filterCountries, setFilterCountries] = useState([]);
  const [filterTopics, setFilterTopics] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [filterSearch, setFilterSearch] = useState('');

  const {id} = useParams();

  const TOPICS_NAMES_MAP = new Map(
      state.issueCodes.map((topic) => [topic.CODE, topic.ISSUE]),
  );

  const filteredData = state.trackerData.filter((data) => {
    if (filterCountries.length) {
      const countryMatch = filterCountries.some((code) => {
        return data.countries.includes(code);
      });
      if (!countryMatch) return false;
    }

    if (filterTopics.length) {
      const topicMatch = filterTopics.some((code) => {
        return data.topics
            .map((t) => t.toLowerCase())
            .includes(code.toLowerCase());
      });
      if (!topicMatch) return false;
    }

    if (filterDate) {
      return data.date.isSame(filterDate, 'month');
    }

    if (filterSearch) {
      return data.event.toLowerCase().includes(filterSearch.toLowerCase());
    }

    if (id) {
      return data.id === id;
    }

    return true;
  });

  const copyCitation = (row: DataRow) => {
    const citation = generateCitation(row);
    navigator.clipboard.writeText(citation);
  };

  const copyLink = (row: DataRow) => {
    navigator.clipboard.writeText(window.location.href + '/' + row.id);
  };

  const highlighted = (text: string, search: string) => {
    if (!search) return text;
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === search.toLowerCase() ?
                {backgroundColor: 'yellow'} :
                {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const trackerColumns = [
    {
      dataIndex: 'event',
      title: () => (
        <div>
          <span>Events</span>
        </div>
      ),
      render: (value: string, row: DataRow, index: number) => {
        return (
          <div>
            <span className="event-date">
              {row.date.format('DD MMMM YYYY')}
            </span>
            <p className="event-text">
              {highlighted(row.event, filterSearch)}
              {row.links.map((link, i) => {
                return (
                  <span key={i}>
                    <a href={link} target="_blank" rel="noreferrer">
                      [ {i + 1} ]
                    </a>
                  </span>
                );
              })}
            </p>
            <div className="event-actions">
              {row.id && (
                <Tooltip title="Copy link">
                  <Button
                    onClick={() => copyLink(row)}
                    icon={<LinkOutlined />}
                    size="small"
                    type="ghost"
                  ></Button>
                </Tooltip>
              )}
              <Tooltip title="Copy as citation">
                <Button
                  onClick={() => copyCitation(row)}
                  icon={<CopyOutlined />}
                  size="small"
                  type="ghost"
                ></Button>
              </Tooltip>
            </div>
            <div className="event-meta">
              <div>
                {row.countries.map((code) => {
                  return (
                    <div key={code} className={`flag:${code} country-icon`} />
                  );
                })}
              </div>
              <div>
                {row.topics.map((code: string, i: number) => {
                  return (
                    <Tag
                      key={i}
                      color={TOPICS_COLOR_MAP.get(code.toUpperCase())}
                    >
                      {TOPICS_NAMES_MAP.get(code.toUpperCase())}
                    </Tag>
                  );
                })}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const handleCountryChange = (value: string[]) => {
    console.log(value);
    setFilterCountries(value);
  };

  const handleTopicChange = (value: string[]) => {
    console.log(value);
    setFilterTopics(value);
  };

  const handleMonthChange = (value: any) => {
    console.log(value);
    setFilterDate(value ? value.format() : null);
  };

  return (
    <div className="Tracker">
      <h1>EU-Taiwan Tracker</h1>
      {id ? (
        <div className="back-to-tracker">
          <Button
            type="primary"
            size="large"
            icon={<BackwardOutlined />}
            onClick={() => {
              window.location.href = '/tracker';
            }}
          >
            Back to Tracker
          </Button>
        </div>
      ) : (
        <>
          <p className="about">
            The EU-Taiwan Tracker is a website dedicated to providing
            comprehensive information about the development of relations between
            Taiwan and the 27 EU member states from January 2019 onwards. This
            tool is for anyone from the scholarly, policymaking, and
            journalistic community working on EU-Taiwan relations.
          </p>
          <p className="about">
            Events are listed chronologically, with the latest news listed on
            the top. Events can be filtered by topic, country, or both. Each
            entry contains one or more links to English- and Chinese-language
            media sources. Data entries are updated on a weekly basis.&nbsp;
          </p>
          <p className="about">
            Did we miss anything?{' '}
            <a href="mailto:kironska@ceias.eu">Let us know.</a>
          </p>
          <br />
          <h2>Filter events</h2>
          <div className="filters">
            <div>
              <label className="tws-label">Countries</label>
              <br />
              <Select
                mode="multiple"
                allowClear
                showArrow
                style={{minWidth: '200px'}}
                placeholder="Please select"
                onChange={handleCountryChange}
                tagRender={(props: any) => {
                  return <div className={`flag:${props.value}`} />;
                }}
              >
                {state.countryCodes
                    .map((c) => c.CODE)
                    .map((code) => {
                      return (
                        <Select.Option key={code}>
                          <div className={`flag:${code}`} />
                          <span>{code}</span>
                        </Select.Option>
                      );
                    })}
              </Select>
            </div>
            <div>
              <label className="tws-label">Topics</label>
              <br />
              <Select
                mode="multiple"
                maxTagCount={2}
                allowClear
                showArrow
                style={{minWidth: '200px'}}
                placeholder="Please select"
                onChange={handleTopicChange}
                tagRender={(props: any) => {
                  return (
                    <Tag color={TOPICS_COLOR_MAP.get(props.value)}>
                      {TOPICS_NAMES_MAP.get(props.value)}
                    </Tag>
                  );
                }}
              >
                {state.issueCodes.map((issue, i) => {
                  return (
                    <Select.Option key={issue.CODE} value={issue.CODE}>
                      <Tag color={TOPICS_COLOR_MAP.get(issue.CODE)}>
                        {issue.ISSUE}
                      </Tag>
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div>
              <label className="tws-label">Month</label>
              <br />
              <DatePicker onChange={handleMonthChange} picker="month" />
            </div>
            <div>
              <label className="tws-label">Search</label>
              <br />
              <Search
                placeholder="Search events"
                onChange={(e) => setFilterSearch(e.target.value)}
              />
            </div>
          </div>
        </>
      )}
      <div className="table-wrapper">
        <Table columns={trackerColumns} dataSource={filteredData}></Table>
      </div>
    </div>
  );
}
