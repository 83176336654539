import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import './App.scss';
import './App.less';
import Tracker from './pages/Tracker';
import {slide as Menu} from 'react-burger-menu';
import ceiasLogo from './assets/images/ceias-logo.png';
import About from './pages/About';
import Analyses from './pages/Analyses';
import moment from 'moment';
import CookieConsent from 'react-cookie-consent';
import {
  DownloadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from '@ant-design/icons';

interface CountryCode {
  COUNTRY: string;
  CODE: string;
}

interface IssueCode {
  ISSUE: string;
  CODE: string;
}

interface Team {
  NAME: string;
  TITLE: string;
  EMAIL: string;
  PICTURE: string;
}

interface Analysis {
  TITLE: string;
  DATE: moment.Moment;
  AUTHOR: string;
  LINK: string;
  PICTURE: string;
}

export interface AppState {
  countryCodes: CountryCode[];
  issueCodes: IssueCode[];
  trackerData: DataRow[];
  team: Team[];
  analyses: Analysis[];
}

export interface DataRow {
  id: string;
  key: string;
  date: moment.Moment;
  countries: string[];
  topics: string[];
  event: string;
  links: string[];
  tw_link: string;
}

function App() {
  const [countryCodes, setCountryCodes] = useState<CountryCode[]>([]);
  const [issueCodes, setIssueCodes] = useState<IssueCode[]>([]);
  const [trackerData, setTrackerData] = useState<any[]>([]);
  const [team, setTeam] = useState<any[]>([]);
  const [analyses, setAnalyses] = useState<any[]>([]);

  // Load all data
  useEffect(() => {
    fetch(
        'https://opensheet.elk.sh/1vCgKt2SO-kgtoeS-mkQDSGVHFPCMug8kbR7xYuxxjGU/Country%20Codes',
    )
        .then((res) => res.json())
        .then((data: CountryCode[]) => {
          setCountryCodes(data);
        });
    fetch(
        'https://opensheet.elk.sh/1vCgKt2SO-kgtoeS-mkQDSGVHFPCMug8kbR7xYuxxjGU/Issue%20Codes',
    )
        .then((res) => res.json())
        .then((data: IssueCode[]) => {
          setIssueCodes(data);
        });
    fetch(
        'https://opensheet.elk.sh/1vCgKt2SO-kgtoeS-mkQDSGVHFPCMug8kbR7xYuxxjGU/Tracker%20Data',
    )
        .then((res) => res.json())
        .then((data: IssueCode[]) => {
          const processedData: DataRow[] = data.map((d: any, i: number) => {
            return {
              id: d.ID,
              key: `${d.ID}-${i}`,
              date: moment(d.DATE),
              countries: (d['COUNTRY CODE'] || '')
                  .split(',')
                  .map((c: string) => c && c.trim())
                  .filter((c: string) => c),
              topics: (d['ISSUE CODE'] || '')
                  .split(',')
                  .map((c: string) => c && c.trim())
                  .filter((c: string) => c),
              event: d.EVENT,
              links: [
                d['HYPERLINK 1'],
                d['HYPERLINK 2'],
                d['HYPERLINK 3'],
                d['HYPERLINK 4'],
                d['HYPERLINK 5'],
                d['HYPERLINK 6'],
                d['HYPERLINK 7'],
                d['TW HYPERLINK'],
              ].filter((l) => l && l.trim()),
              tw_link: d['TW HYPERLINK'],
            };
          });
          processedData.sort((a, b) => {
            return b.date.diff(a.date);
          });
          setTrackerData(processedData);
        });
    fetch(
        'https://opensheet.elk.sh/1vCgKt2SO-kgtoeS-mkQDSGVHFPCMug8kbR7xYuxxjGU/Team',
    )
        .then((res) => res.json())
        .then((data: IssueCode[]) => {
          setTeam(data);
        });
    fetch(
        'https://opensheet.elk.sh/1vCgKt2SO-kgtoeS-mkQDSGVHFPCMug8kbR7xYuxxjGU/Analyses',
    )
        .then((res) => res.json())
        .then((data: Analysis[]) => {
          const processedData: Analysis[] = data.map((d: any) => {
            return {
              ...d,
              DATE: moment(d.DATE),
            };
          });
          processedData.sort((a, b) => {
            return b.DATE.diff(a.DATE);
          });
          setAnalyses(processedData);
        });
  }, []);

  const appState = {
    countryCodes,
    issueCodes,
    trackerData,
    team,
    analyses,
  };

  const downloadCsv = () => {
    const data = trackerData.map((d) => {
      return {
        Date: d.date.format('YYYY-MM-DD'),
        Countries: d.countries.join(', '),
        Topics: d.topics.join(', '),
        Event: d.event,
        Links: d.links.join(', '),
        TaiwanSourceLink: d.tw_link,
      };
    });

    const csv = [
      Object.keys(data[0]).join(','),
      ...data.map((row) => Object.values(row).join(',')),
    ].join('\n');

    const blob = new Blob([csv], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'ceias-tracker.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="App">
      <div id="outer-container">
        <Menu pageWrapId={'page-wrap'} noOverlay width={240} right>
          <a id="home" className="menu-item" href="/">
            Tracker
          </a>
          <a id="analyses" className="menu-item" href="/analyses">
            Analyses
          </a>
          <a id="about" className="menu-item" href="/about">
            About
          </a>
          <a href="#" onClick={(e) => {
            e.preventDefault();
            downloadCsv();
          }}>
            <DownloadOutlined />
            &nbsp;Download Data
          </a>
        </Menu>
        <main id="page-wrap">
          <a href="/">
            <img src={ceiasLogo} alt="CEIAS" className="logo" />
          </a>
          <Routes>
            <Route path="/" element={<Navigate to="/tracker" />} />
            <Route path="/tracker" element={<Tracker state={appState} />} />
            <Route path="/tracker/:id" element={<Tracker state={appState} />} />
            <Route path="/analyses" element={<Analyses state={appState} />} />
            <Route path="/about" element={<About state={appState} />} />
          </Routes>
        </main>
      </div>
      <div className="footer">
        <div>
          <p className="copyright">
            © CEIAS {new Date().getFullYear()}. All rights reserved.
          </p>
          <p>
            <a href="https://ceias.eu">www.ceias.eu</a>
            <span>&nbsp;|&nbsp;</span>
            <a href="mailto:office@ceias.eu">office@ceias.eu</a>
          </p>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/CEIASeu">
            <FacebookOutlined style={{fontSize: 28}} />
          </a>
          <a href="https://twitter.com/ceias_eu">
            <TwitterOutlined style={{fontSize: 28}} />
          </a>
          <a href="https://www.linkedin.com/company/ceias-eu">
            <LinkedinOutlined style={{fontSize: 28}} />
          </a>
          <a href="https://www.instagram.com/ceias_eu/">
            <InstagramOutlined style={{fontSize: 28}} />
          </a>
        </div>
      </div>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
